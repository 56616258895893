<template>
  <div id="desligamento">
    <Snackbar
      :label="msg"
      v-model="snackbarError"
      top
      right
      :timeout="2500"
      :tipo="alerta"
    />

    <v-form>
      <h6 class="titleHeader mb-3">Fluxo de Solicitar Rescisão</h6>
      <v-row>
        <v-col>
          <h5>Atenção</h5>
          <p class="caption">Os campos com
          <span class="count-warning">*</span>
          são obrigatórios.</p>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="4">
          <v-autocomplete
            v-model="funcionario"
            :items="opcoesNovas"
            label="Email do profissional"
            :search-input.sync="funcionarioS"
            :rules="emailRules"
            outlined
            dense
            @change="getDadosFuncionario(funcionario)"
          ></v-autocomplete>
        </v-col>
        <div class="caption count-warning">*</div>

        <v-col cols="4">
          <v-text-field
            v-model="nomeUsuario"
            label="Nome do Profissional"
            outlined
            dense
            clearable
            auto-grow
          ></v-text-field>
        </v-col>
        <div class="caption count-warning">*</div>
      </v-row>

      <v-row>
        <v-col cols="5">
          <v-radio-group
            v-model="vinculo"
            row
            label="Vínculo: "
          >
            <v-radio
              label="CLT"
              value="CLT"
            ></v-radio>
            <v-radio
              label="Estágio"
              value="Estágio"
            ></v-radio>
            <v-radio
              label="Sócio"
              value="Sócio"
            ></v-radio>
            <div class="caption count-warning">*</div>
          </v-radio-group>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="4">
          <v-select
            v-model="solicitante"
            :items="itensSolicitante"
            label="Solicitante"
            outlined
            dense
          ></v-select>
        </v-col>
        <div class="caption count-warning">*</div>

        <v-col
            cols="4"
            v-if="exibeTipoDesligamento"
          >
          <v-select
            v-model="tipoDesligamento"
            :items="itensDesligamento"
            label="Tipo de Desligamento"
            outlined
            dense
          ></v-select>
        </v-col>
        <div v-if="exibeTipoDesligamento" class="caption count-warning">*</div>
      </v-row>

      <v-col v-if="exibeAlertaColaborador">
        <v-icon>mdi-alert</v-icon>
        <p class="caption"><strong>Atenção: </strong>Para desligamentos por parte do colaborador
         é obrigatório anexar a carta de pedido de demissão.</p>
      </v-col>
      <v-col v-else-if="exibeAlertaEmpresa">
        <v-icon>mdi-alert</v-icon>
        <p class="caption"><strong>Atenção: </strong>Desligamentos por parte da empresa devem
         ser solicitados com no mínimo 2 dias de antecedência,<br> casos urgentes devem ser tratados
         com o processo de rescisão.</p>
      </v-col>

      <v-row>
        <v-col
          cols="4"
          v-if="exibeTipoAviso"
        >
          <v-select
            v-model="tipoAviso"
            :items="itensAviso"
            label="Tipo de aviso"
            outlined
            dense
          ></v-select>
        </v-col>
        <div v-if="exibeTipoAviso"
        class="caption count-warning">*</div>
      </v-row>

      <v-row>
        <v-col
          cols="4"
          v-if="exibeCausas"
        >
          <v-select
            v-model="causa"
            :items="causas"
            label="Causas"
            outlined
            dense
          ></v-select>
        </v-col>
        <div v-if="exibeCausas" class="caption count-warning">*</div>
      </v-row>

      <v-row>
        <v-col
          cols="4"
          v-if="exibeDataDesligamento"
        >
          <v-menu
            v-model="menuDataDesligamento"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dataFormatadaDesligamento"
                label="Data do Desligamento"
                readonly
                outlined
                dense
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="dataDesligamento"
              @input="menuDataDesligamento = false"
              :first-day-of-week="0"
              locale="pt-br"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <div v-if="exibeDataDesligamento" class="caption count-warning">*</div>
      </v-row>

      <v-row>
        <v-col
          cols="4"
          v-if="exibeDataInicioAvisoEdataFim"
        >
          <v-menu
            v-model="menuDataInicioAviso"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dataFormatadaInicioAviso"
                label="Data de Inicio do Aviso"
                readonly
                outlined
                dense
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="dataInicioAviso"
              @input="menuDataInicioAviso = false"
              :first-day-of-week="0"
              locale="pt-br"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <div v-if="exibeDataInicioAvisoEdataFim" class="caption count-warning">*</div>
      </v-row>

      <v-row>
        <v-col
          cols="4"
          v-if="exibeDataInicioAvisoEdataFim"
        >
          <v-text-field
            v-model="quantidadeDias"
            label="Quantidade de Dias"
            type="number"
            outlined
            dense
            max="30"
            min="0"
            clearable
            auto-grow
            :disabled="tipoDesligamento === 'Aviso prévio' ? true : false"
          ></v-text-field>
        </v-col>
        <div v-if="exibeDataInicioAvisoEdataFim" class="caption count-warning">*</div>
      </v-row>

      <v-row>
        <v-col
          cols="4"
          v-if="exibeDataInicioAvisoEdataFim"
        >
          <v-menu
            v-model="menuDataFimAviso"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dataFormatadaFim"
                label="Data fim do aviso"
                readonly
                outlined
                dense
                v-bind="attrs"
                v-on="on"
                disabled
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="dataFimAviso"
              @input="menuDataFimAviso = false"
              :first-day-of-week="0"
              locale="pt-br"
              :min="dataInicioAviso"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <div v-if="exibeDataInicioAvisoEdataFim" class="caption count-warning">*</div>
      </v-row>

      <v-row>
        <v-col
          cols="4"
          v-if="exibeDesconto"
        >
          <v-radio-group
            v-model="descontoAviso"
            row
            label="Desconto de aviso"
          >
            <v-radio
              label="Sim (Descontar)"
              :value="'Sim (Descontar)'"
            ></v-radio>
            <v-radio
              label="Não (Dispensado)"
              :value="'Não (Dispensado)'"
            ></v-radio>
            <div class="caption count-warning">*</div>
          </v-radio-group>
        </v-col>
      </v-row>

      <v-row>
        <v-col
          cols="4"
          v-if="exibeDescontoAvisoMulta"
        >
          <v-radio-group
            v-model="descontoAvisoMulta"
            row
            label="Desconto - Multa quebra de contrato"
          >
            <v-radio
              label="Sim (Descontar)"
              :value="'Sim (Descontar)'"
            ></v-radio>
            <v-radio
              label="Não (Dispensado)"
              :value="'Não (Dispensado)'"
            ></v-radio>
            <div class="caption count-warning">*</div>
          </v-radio-group>
        </v-col>
      </v-row>

      <v-col v-if="hideField">
        <v-icon>mdi-check</v-icon>
        <p class="caption"><strong>Salvo!</strong>
          A Data de contratação do Profissional e
          Telefone pessoal do {{ nomeUsuario }} foram salvos.</p>
      </v-col>

      <v-row>
        <v-col
          cols="4"
          v-if="!hideField"
        >
          <v-text-field
            v-model="dataAdmissao"
            label="Data de contratação do Profissional"
            outlined
            dense
            clearable
          ></v-text-field>
        </v-col>
        <div v-if="!hideField" class="caption count-warning">*</div>

        <v-col cols="4">
          <v-autocomplete
            v-model="emailGestor"
            :items="opcoesNovas"
            label="Email do Gestor"
            :rules="emailRules"
            :search-input.sync="emailGestorS"
            outlined
            dense
          ></v-autocomplete>
        </v-col>
        <div class="caption count-warning">*</div>
      </v-row>

      <v-row>
        <v-col
          cols="4"
        >
          <v-text-field
            v-model="cc"
            label="CC que vai o custo de rescisão"
            outlined
            dense
            clearable
          ></v-text-field>
        </v-col>
        <div class="caption count-warning">*</div>

        <v-col cols="4">
          <v-select
            v-model="unidade"
            :items="sedes"
            label="Unidade"
            outlined
            dense
          ></v-select>
        </v-col>
        <div class="caption count-warning">*</div>
      </v-row>

      <v-row>
        <v-col
          cols="4"
          v-if="!hideField"
        >
          <v-text-field
            v-mask="['(##) ####-####', '(##) #####-####']"
            v-model="telefone"
            label="Telefone do profissional"
            outlined
            dense
            clearable
            auto-grow
          ></v-text-field>
        </v-col>
        <div v-if="!hideField" class="caption count-warning">*</div>

        <v-col cols="4">
          <v-text-field
            v-model="emailPessoal"
            label="E-mail pessoal do profissional"
            outlined
            dense
            clearable
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="3">
          <v-radio-group
            v-model="absorvido"
            row
            label="Profissional absorvido pelo cliente? "
          >
            <v-radio
              label="Sim"
              :value="'Sim'"
            ></v-radio>
            <v-radio
              label="Não"
              :value="'Não'"
            ></v-radio>
            <div class="caption count-warning">*</div>
          </v-radio-group>
        </v-col>

        <v-col cols="3">
          <v-radio-group
            v-model="alocado"
            row
            label="Profissional alocado no cliente? "
          >
            <v-radio
              label="Sim"
              :value="'Sim'"
            ></v-radio>
            <v-radio
              label="Não"
              :value="'Não'"
            ></v-radio>
            <div class="caption count-warning">*</div>
          </v-radio-group>
        </v-col>
      </v-row>

      <v-row>
        <v-col
          cols="4">
          <v-select
            v-model="motivo"
            :items="itensMotivo"
            label="Motivo"
            outlined
            dense
          ></v-select>
        </v-col>
        <div class="caption count-warning">*</div>
      </v-row>

      <v-row>
        <v-col cols="4">
          <v-select
            v-model="futuraContratacao"
            :items="contratacao"
            label="Você indica como futura Contratação?"
            outlined
            dense
          ></v-select>
        </v-col>
        <div class="caption count-warning">*</div>
      </v-row>

      <v-row>
        <v-col cols="8">
          <v-textarea
            v-model="justifique"
            label="Detalhe aqui os motivos da saída do profissional"
            outlined
            dense
            clearable
            auto-grow
          ></v-textarea>
          <p class="caption text-right">
            Mínimo 40 caracteres /
            <span :class="justifique.length < 40 ? 'count-warning' : 'count-success'">
              {{ countLengthJustifique }}
            </span>
          </p>
        </v-col>
        <div class="caption count-warning">*</div>
      </v-row>

    </v-form>
  </div>
</template>

<script>
import moment from 'moment';
import { mask } from 'vue-the-mask';
import Snackbar from '../Snackbar';

export default {
  name: 'Desligamento',

  components: {
    Snackbar,
  },

  directives: { mask },

  data: () => ({
    funcionario: '',
    emailRules: [
      v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid',
    ],
    funcionarioS: '',
    emailOpcoes: [],
    opcoesNovas: [],
    sedes: [],

    opcoesFiltradas: {},

    nomeUsuario: '',
    vinculo: '',
    solicitante: '',
    tipoDesligamento: '',
    tipoAviso: '',

    causa: '',
    causas: ['Abandono de Emprego', 'Outros (Orientação Prévia do Jurídico)'],

    menuDataDesligamento: false,
    menuDataInicioAviso: false,
    menuDataFimAviso: false,
    dataDesligamento: new Date().toISOString().substr(0, 10),
    dataInicioAviso: new Date().toISOString().substr(0, 10),
    dataFimAviso: '',

    descontoAviso: '',
    descontoAvisoMulta: '',

    emailGestor: '',
    emailGestorS: '',

    dataAdmissao: '',
    cc: '',
    unidade: '',
    telefone: '',
    emailPessoal: '',

    absorvido: '',
    alocado: '',
    motivo: '',

    futuraContratacao: '',
    contratacao: ['Sim', 'Não'],

    justifique: '',

    dadosFluxo: {},
    versao: 'v1',

    hideField: false,

    snackbarError: false,
    alerta: 'info',
    msg: '',
  }),

  computed: {
    itensSolicitante() {
      if (this.vinculo === 'Estágio') {
        return ['Empresa', 'Estagiário'];
      }
      return ['Empresa', 'Colaborador'];
    },

    itensDesligamento() {
      if (this.vinculo === 'CLT' && this.solicitante === 'Empresa') {
        return ['Aviso prévio', 'Término de contrato', 'Término de contrato antecipado', 'Justa causa'];
      }

      if (this.vinculo === 'CLT' && this.solicitante === 'Colaborador') {
        return ['Pedido de demissão', 'Término de contrato', 'Término de contrato antecipado'];
      }

      if (this.vinculo === 'Estágio' && this.solicitante === 'Empresa') {
        return ['Efetivação', 'Término de contrato', 'Término de contrato antecipado'];
      }

      if (this.vinculo === 'Estágio' && this.solicitante === 'Estagiário') {
        return ['Término de contrato', 'Pedido de demissão'];
      }

      return ['Selecionar campos anteriores'];
    },

    itensAviso() {
      if (this.solicitante === 'Empresa' && this.tipoDesligamento === 'Aviso prévio') {
        return ['Indenizado', 'Trabalhado'];
      }

      if (this.solicitante === 'Colaborador' && this.tipoDesligamento === 'Pedido de demissão') {
        return ['Ausência de Aviso', 'Trabalhado'];
      }

      return ['Selecionar campos anteriores'];
    },

    dataFormatadaDesligamento() {
      return this.dataDesligamento ? moment(this.dataDesligamento).format('DD/MM/YYYY') : '';
    },

    dataFormatadaInicioAviso() {
      return this.dataInicioAviso ? moment(this.dataInicioAviso).format('DD/MM/YYYY') : '';
    },

    dataFormatadaFim() {
      return moment(this.dataInicioAviso).add(this.dataFimAviso ? this.dataFimAviso : this.quantidadeDias, 'days').format('DD/MM/YYYY');
    },

    quantidadeDias: {
      get() {
        if (this.tipoDesligamento === 'Aviso prévio' && this.tipoAviso === 'Trabalhado') {
          return 30;
        }
        return 0;
      },
      set(newValue) {
        if ((this.tipoDesligamento !== 'Aviso prévio' || this.tipoAviso !== 'Trabalhado')
          && this.tipoDesligamento !== 'Pedido de demissão') {
          this.quantidadeDias = 0;
          this.dataFimAviso = 0;
        }
        this.dataFimAviso = parseInt(newValue, 10);
      },
    },

    exibeTipoDesligamento() {
      return this.vinculo !== 'Sócio';
    },

    exibeAlertaColaborador() {
      return this.solicitante === 'Colaborador';
    },

    exibeAlertaEmpresa() {
      return this.solicitante === 'Empresa';
    },

    exibeTipoAviso() {
      return (this.solicitante === 'Empresa' && this.tipoDesligamento === 'Aviso prévio')
        || (this.solicitante === 'Colaborador' && this.tipoDesligamento === 'Pedido de demissão');
    },

    exibeCausas() {
      return this.tipoDesligamento === 'Justa causa';
    },

    exibeDataDesligamento() {
      return this.tipoAviso !== 'Trabalhado';
    },

    exibeDataInicioAvisoEdataFim() {
      return this.tipoAviso === 'Trabalhado';
    },

    exibeDescontoAvisoMulta() {
      return this.solicitante === 'Colaborador'
        && this.tipoDesligamento === 'Término de contrato antecipado';
    },

    exibeDesconto() {
      return ((this.tipoDesligamento === 'Pedido de demissão' && this.tipoAviso === 'Trabalhado') && (this.dataFimAviso < 30 && this.quantidadeDias !== 30))
       || (this.tipoDesligamento === 'Pedido de demissão' && this.tipoAviso === 'Ausência de Aviso');
    },

    itensMotivo() {
      if (this.solicitante === 'Colaborador' || this.solicitante === 'Estagiário') {
        return [
          'Absorção cliente',
          'Absorção Meta',
          'Atividades desenvolvidas atualmente',
          'Cultura Organizacional Cliente',
          'Cultura Organizacional Meta',
          'Modalidade de trabalho (presencial / remoto)',
          'Mudança de carreira',
          'Mudança de localidade',
          'Novos desafios (cargo / projeto / empresa)',
          'Ociosidade',
          'Problemas pessoais',
          'Proposta internacional',
          'Relacionamento',
          'Remuneração e Benefícios',
        ];
      }

      if (this.solicitante === 'Empresa' || this.solicitante === 'Estagiário') {
        return [
          'Abandono',
          'Absorção cliente',
          'Absorção Meta',
          'Cultura organizacional Cliente',
          'Cultura organizacional Meta',
          'Desempenho',
          'Efetivação',
          'Falecimento',
          'Faltas/Atraso',
          'Ociosidade',
          'Redução projeto',
          'Relacionamento',
          'Término projeto',
        ];
      }

      return ['Selecionar campos anteriores'];
    },

    countLengthJustifique() {
      return this.justifique === '' ? '0' : this.justifique.length;
    },

    verificaCampos() {
      if (this.vinculo === 'Estágio') {
        return (
          !!this.vinculo
          && !!this.solicitante
          && !!this.tipoDesligamento
          && !!this.dataDesligamento
          && !!this.emailGestor
          && !!this.cc
          && !!this.unidade
          && !!this.telefone
          && !!this.absorvido
          && !!this.alocado
          && !!this.motivo
          && !!this.futuraContratacao
          && !!this.justifique
        );
      }

      if (this.vinculo === 'Sócio') {
        return (
          !!this.vinculo
          && !!this.solicitante
          && !!this.dataDesligamento
          && !!this.emailGestor
          && !!this.cc
          && !!this.unidade
          && !!this.telefone
          && !!this.absorvido
          && !!this.alocado
          && !!this.motivo
          && !!this.futuraContratacao
          && !!this.justifique
        );
      }

      return (
        !!this.vinculo
        && !!this.solicitante
        && !!this.tipoDesligamento
        && (!!this.dataDesligamento || (!!this.dataInicioAviso && !!this.dataFimAviso))
        && !!this.emailGestor
        && !!this.cc
        && !!this.unidade
        && !!this.telefone
        && !!this.absorvido
        && !!this.alocado
        && !!this.motivo
        && !!this.futuraContratacao
        && !!this.justifique
      );
    },
  },

  watch: {
    funcionarioS(newValue) {
      this.validaOpcoesEmail(newValue);
    },

    emailGestorS(newValue) {
      this.validaOpcoesEmail(newValue);
    },
  },
  created() {
    moment.locale('pt-br');
  },

  mounted() {
    try {
      this.listaSedes();
      this.getInfosDesligamento();
    } catch (error) {
      console.error(error);
    }
  },

  methods: {
    validaOpcoesEmail(newValue) {
      this.opcoesNovas = [...this.getOpcoesNovas(this.opcoesFiltradas),
        { text: newValue, value: newValue },
      ];
    },

    getOpcoesNovas(response) {
      if (response.length > 0) {
        return response.map(item => ({
          text: item.email,
          value: item.email,
        }));
      }
      return [];
    },

    async getInfosDesligamento() {
      try {
        const { data: response } = await this.$http.get('/get-desligamentos');
        this.opcoesFiltradas = response;

        this.opcoesNovas = await this.getOpcoesNovas(response);
      } catch (error) {
        console.error(error);
      }
    },

    async getDadosFuncionario(emailFuncionario) {
      try {
        const [result] = this.opcoesFiltradas
          .filter((funcionario => funcionario.email === emailFuncionario
          ));

        const {
          nomeUsuario, dataAdmissao, unidade, telefone1,
        } = result;

        if (result) {
          this.hideField = true;
        }

        this.nomeUsuario = nomeUsuario;
        this.dataAdmissao = dataAdmissao ? moment(dataAdmissao).format('L') : '';
        this.unidade = unidade;
        this.telefone = telefone1;
      } catch (error) {
        console.error(error);
      }
    },

    async listaSedes() {
      try {
        const { data: response } = await this.$http.get('/sedes/');
        this.sedes = response.map(item => ({
          value: item.nome,
          text: item.nome,
        }));
      } catch (error) {
        console.error(error);
      }
    },

    verificaQuantidadeDias(dias) {
      return dias < 31;
    },

    trataResposta(dadosFluxo) {
      const respostasModel = {};

      if (dadosFluxo.funcionario) respostasModel.column_campo_1 = dadosFluxo.funcionario.trim();
      if (dadosFluxo.nomeUsuario) respostasModel.column_campo_2 = dadosFluxo.nomeUsuario.trim();
      if (dadosFluxo.vinculo) respostasModel.column_campo_3 = dadosFluxo.vinculo.trim();
      if (dadosFluxo.solicitante) respostasModel.column_campo_4 = dadosFluxo.solicitante.trim();
      if (dadosFluxo.tipoDesligamento) {
        respostasModel.column_campo_5 = dadosFluxo.tipoDesligamento.trim();
      }
      if (dadosFluxo.tipoAviso) respostasModel.column_campo_6 = dadosFluxo.tipoAviso.trim();
      if (dadosFluxo.causa) respostasModel.column_campo_7 = dadosFluxo.causa.trim();
      if (dadosFluxo.dataDesligamento && this.tipoAviso !== 'Trabalhado') {
        respostasModel.column_campo_8 = dadosFluxo.dataDesligamento.trim();
      }
      if (dadosFluxo.dataInicioAviso && this.tipoAviso === 'Trabalhado') {
        respostasModel.column_campo_9 = dadosFluxo.dataInicioAviso.trim();
      }
      if (dadosFluxo.quantidadeDias) {
        respostasModel.column_campo_10 = dadosFluxo.quantidadeDias;
      }
      if (dadosFluxo.dataFimAviso && this.tipoAviso === 'Trabalhado') respostasModel.column_campo_11 = dadosFluxo.dataFimAviso.trim();
      if (dadosFluxo.descontoAviso) {
        respostasModel.column_campo_12 = dadosFluxo.descontoAviso.trim();
      }
      if (dadosFluxo.descontoAvisoMulta) {
        respostasModel.column_campo_13 = dadosFluxo.descontoAvisoMulta.trim();
      }
      if (dadosFluxo.dataAdmissao) respostasModel.column_campo_14 = dadosFluxo.dataAdmissao.trim();
      if (dadosFluxo.emailGestor) respostasModel.column_campo_15 = dadosFluxo.emailGestor.trim();
      if (dadosFluxo.cc) respostasModel.column_campo_16 = dadosFluxo.cc.trim();
      if (dadosFluxo.unidade) respostasModel.column_campo_17 = dadosFluxo.unidade.trim();
      if (dadosFluxo.telefone) respostasModel.column_campo_18 = dadosFluxo.telefone.trim();
      if (dadosFluxo.emailPessoal) respostasModel.column_campo_19 = dadosFluxo.emailPessoal.trim();
      if (dadosFluxo.absorvido) respostasModel.column_campo_20 = dadosFluxo.absorvido.trim();
      if (dadosFluxo.alocado) respostasModel.column_campo_21 = dadosFluxo.alocado.trim();
      if (dadosFluxo.motivo) respostasModel.column_campo_22 = dadosFluxo.motivo.trim();
      if (dadosFluxo.futuraContratacao) {
        respostasModel.column_campo_23 = dadosFluxo.futuraContratacao.trim();
      }
      if (dadosFluxo.justifique) respostasModel.column_campo_24 = dadosFluxo.justifique.trim();

      respostasModel.validation = true;
      this.newDadosFluxo = respostasModel;
    },

    montaBody() {
      this.dadosFluxo = {
        validation: true,
        funcionario: this.funcionario,
        nomeUsuario: this.nomeUsuario,
        vinculo: this.vinculo,
        solicitante: this.solicitante,
        tipoDesligamento: this.tipoDesligamento,
        tipoAviso: this.tipoAviso,
        causa: this.causa,
        dataDesligamento: this.dataFormatadaDesligamento,
        dataInicioAviso: this.dataFormatadaInicioAviso,
        quantidadeDias: this.quantidadeDias || this.dataFimAviso,
        dataFimAviso: this.dataFormatadaFim,
        descontoAviso: this.descontoAviso,
        descontoAvisoMulta: this.descontoAvisoMulta,
        dataAdmissao: this.dataAdmissao,
        emailGestor: this.emailGestor,
        cc: this.cc,
        unidade: this.unidade,
        telefone: this.telefone,
        emailPessoal: this.emailPessoal,
        absorvido: this.absorvido,
        alocado: this.alocado,
        motivo: this.motivo,
        futuraContratacao: this.futuraContratacao,
        justifique: this.justifique,
        versao: this.versao,
      };
    },

    enviaJSON() {
      if (!this.verificaCampos) {
        this.dadosFluxo = {
          validation: false,
          errorMsg: 'O campo é obrigatório.',
        };
        this.alerta = 'error';
        this.msg = 'Existem campos obrigatórios que não foram preenchidos';
        this.snackbarError = true;
        this.$emit('dadosFluxo', this.dadosFluxo);
      }
      if (this.verificaCampos) {
        if (this.justifique.length >= 40) {
          this.montaBody();
          if (this.verificaQuantidadeDias(this.dadosFluxo.quantidadeDias)) {
            this.alerta = 'success';
            this.msg = 'Atendimento aberto com sucesso';
            this.snackbarError = true;
            this.trataResposta(this.dadosFluxo);
            this.$emit('dadosFluxo', this.newDadosFluxo);
          } else {
            this.alerta = 'error';
            this.msg = 'O limite para quantidade de dias é 30.';
            this.snackbarError = true;
          }
        } else {
          this.dadosFluxo = {
            validation: false,
            errorMsg: 'Mínimo 40 caracteres para este(s) campo(s).',
          };
          this.alerta = 'error';
          this.msg = 'Mínimo de 40 caracteres para este(s) campo(s)';
          this.snackbarError = true;
          this.$emit('dadosFluxo', this.dadosFluxo);
        }
      }
    },
  },

};
</script>

<style>
.count-warning {
  background-color: transparent;
  color: red;
}

.count-success {
  background-color: transparent;
  color: green;
}
</style>
